import React from 'react';

const Particles = () => {
    return (
        <div className='particle-wrapper'>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
            <div className='particle'></div>
        </div>
    );
};

export default Particles;
